import { gql } from "@apollo/client";

export const UPDATE_AUTH_USER_DETAILS = gql`
  mutation UpdateAuthUserDetails(
    $firstName: String!
    $lastName: String!
    $mobileNumber: String!
    $locationId: Int!
  ) {
    userUpdate(
      input: {
        firstName: $firstName
        lastName: $lastName
        mobileNumber: $mobileNumber
        locationId: $locationId
      }
    ) {
      email
      firstName
      lastName
      mobileNumber
      location {
        id
        name
        parent {
          name
        }
      }
      pensilToken
      getChargebeeId
      getSubscriptionDetails
      isUserSubscribed
    }
  }
`;

export const UPDATE_PROFILE_USER_DETAILS = gql`
  mutation UpdateProfileUserDetails($updateInput: UpdateInput!) {
    userUpdate(input: $updateInput) {
      email
      firstName
      lastName
      mobileNumber
      dateOfBirth
      college
      profilePicture {
        filename
        url
      }
      location {
        id
        name
        parent {
          name
        }
      }
    }
  }
`;
