import CustomButton from "@components/adaptors/CustomButton";
import { helpers } from "@gh-classes";
import { variants } from "@variants";
import { Empty, Typography } from "antd";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Fragment } from "react";
import classes from "./EmptyMessage.module.scss";

const { Title, Paragraph } = Typography;

const EmptyMessage = (props) => {
  const {
    messageHeading,
    messageSubInfo,
    customClass,
    handleRedirect,
    buttonText,
    ...rest
  } = props;

  return (
    <Empty
      {...rest}
      className={classNames(classes.customEmptyMessage, customClass)}
      description={
        <Fragment>
          {messageHeading && (
            <Title className={classes.messageHeading}>{messageHeading}</Title>
          )}
          {messageSubInfo && (
            <Paragraph className={classes.messageSubText}>
              {messageSubInfo}
            </Paragraph>
          )}
          {buttonText && (
            <CustomButton
              onClick={handleRedirect}
              variant={variants.secondaryButton}
              title={buttonText}
              customClass={helpers.mt24}
            />
          )}
        </Fragment>
      }
    ></Empty>
  );
};

EmptyMessage.propTypes = {
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  messageHeading: PropTypes.string,
  messageSubInfo: PropTypes.string,
  customClass: PropTypes.string,
  handleRedirect: PropTypes.func,
  buttonText: PropTypes.string,
};

EmptyMessage.defaultProps = {
  image: Empty.PRESENTED_IMAGE_SIMPLE,
  messageHeading: "",
  messageSubInfo: "",
  customClass: "",
  handleRedirect: () => {},
  buttonText: "",
};

export default EmptyMessage;
