export const helpers = {
  textLeft: "text-left",
  textCenter: "text-center",
  textRight: "text-right",
  textLowercase: "text-lowercase",
  textUppercase: "text-uppercase",
  textCapitalize: "text-capitalize",
  textNowrap: "text-nowrap",
  textTruncate: "text-truncate",
  textBreak: "text-break",
  f32: "f-32",
  f28: "f-28",
  f24: "f-24",
  f22: "f-22",
  f20: "f-20",
  f18: "f-18",
  f16: "f-16",
  f14: "f-14",
  f12: "f-12",
  f10: "f-10",
  fontItalic: "font-italic",
  fontBold: "font-bold",
  fontOpensans: "font-opensans",
  fontLato: "font-lato",
  clrBase: "clr-base",
  clrBlack: "clr-black",
  clrWhite: "clr-white",
  clrYellow: "clr-yellow",
  clrLink: "clr-link",
  vAlignTop: "v-align-top",
  vAlignBottom: "v-align-bottom",
  vAlignMiddle: "v-align-middle",
  dNone: "d-none",
  dBlock: "d-block",
  dFlex: "d-flex",
  dGrid: "d-grid",
  dInline: "d-inline",
  dInlineBlock: "d-inline-block",
  dInlineFlex: "d-inline-flex",
  m0: "m-0",
  mt0: "mt-0",
  mb0: "mb-0",
  mr0: "mr-0",
  ml0: "ml-0",
  my0: "my-0",
  mx0: "mx-0",
  mt4: "mt-4",
  mr4: "mr-4",
  mb4: "mb-4",
  ml4: "ml-4",
  my4: "my-4",
  mx4: "mx-4",
  mt8: "mt-8",
  mr8: "mr-8",
  mb8: "mb-8",
  ml8: "ml-8",
  my8: "my-8",
  mx8: "mx-8",
  mt16: "mt-16",
  mr16: "mr-16",
  mb16: "mb-16",
  ml16: "ml-16",
  my16: "my-16",
  mx16: "mx-16",
  mt24: "mt-24",
  mr24: "mr-24",
  mb24: "mb-24",
  ml24: "ml-24",
  my24: "my-24",
  mx24: "mx-24",
  mt32: "mt-32",
  mr32: "mr-32",
  mb32: "mb-32",
  ml32: "ml-32",
  my32: "my-32",
  mx32: "mx-32",
  p0: "p-0",
  pt0: "pt-0",
  pb0: "pb-0",
  pr0: "pr-0",
  pl0: "pl-0",
  py0: "py-0",
  px0: "px-0",
  pt4: "pt-4",
  pr4: "pr-4",
  pb4: "pb-4",
  pl4: "pl-4",
  py4: "py-4",
  px4: "px-4",
  pt8: "pt-8",
  pr8: "pr-8",
  pb8: "pb-8",
  pl8: "pl-8",
  py8: "py-8",
  px8: "px-8",
  pt16: "pt-16",
  pr16: "pr-16",
  pb16: "pb-16",
  pl16: "pl-16",
  py16: "py-16",
  px16: "px-16",
  pt24: "pt-24",
  pr24: "pr-24",
  pb24: "pb-24",
  pl24: "pl-24",
  py24: "py-24",
  px24: "px-24",
  pt32: "pt-32",
  pr32: "pr-32",
  pb32: "pb-32",
  pl32: "pl-32",
  py32: "py-32",
  px32: "px-32",
  pointer: "pointer",
  solidLarge: "solid-large",
  regularLarge: "regular-large",
  solidMedium: "solid-medium",
  regularMedium: "regular-medium",
  solidSmall: "solid-small",
  regularSmall: "regular-small",
  solidTiny: "solid-tiny",
  regularTiny: "regular-tiny",
};
